<template>
    <van-row type="flex" justify="flex-start" class="collect">
        <van-col span="24" class="collect-line">
            <van-loading type="spinner" size="16px" v-if="requesting" class="loading-item" />
            <template v-else>
                <van-icon name="star" size="16px" v-if="isCollected" color="#1989fa" @click="handleRemoveCollect" />
                <van-icon name="star-o" size="16px" v-else @click="handleCollect" />
            </template>
            <span>收藏</span>
        </van-col>
    </van-row>
</template>

<script>
import { isCollected, collected, removeCollect } from '@/api/collect'
import { ACCESS_TOKEN } from '@/utils/constants'

export default {
    name: "Tabbar",
    props: {
        medium: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isCollected: false,
            requesting: false
        }
    },
    methods: {
        handleRemoveCollect () {
            const mediumType = this.medium.type === undefined ? 'articles' : 'medias'
            this.requesting = true
            removeCollect({
                medium_type: mediumType,
                medium_id: this.medium.id
            }).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.isCollected = false
            }).finally(() => {
                this.requesting = false
            })
        },
        handleCollect () {
            const token = this.$ls.get(ACCESS_TOKEN)
            if (!token) {
                this.$notify({ type: 'warning', message: '您还未登录,无法收藏' })
                return
            }
            const mediumType = this.medium.type === undefined ? 'articles' : 'medias'
            this.requesting = true
            collected({
                medium_type: mediumType,
                medium_id: this.medium.id
            }).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.isCollected = true
            }).finally(() => {
                this.requesting = false
            })
        }
    },
    created() {
        const token = this.$ls.get(ACCESS_TOKEN)
        if (!token) {
            return
        }
        const mediumType = this.medium.type === undefined ? 'articles' : 'medias'
        this.requesting = true
        isCollected({
            medium_type: mediumType,
            medium_id: this.medium.id
        }).then(res => {
            this.isCollected = res.data.status
        }).finally(() => {
            this.requesting = false
        })
    }
}
</script>

<style lang="less" scoped>
    .collect {
        .collect-line {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            i, .loading-item {
                margin-right: 5px;
            }
        }
    }
</style>
