<template>
    <div>
        <van-image
            width="100%"
            :src="audio.thumb"
            @load="() => { this.imgLoaded = true }"
            :style="{ height: imgLoaded ? null : '180px' }"
        />

        <van-cell title="" v-if="loading">
            <template #label>
                <van-skeleton title :row="5" style="padding: 0px" />
            </template>
        </van-cell>

        <template v-else>
            <t-content>
                <template #title>{{ audio.title }}</template>
                <template #meta>
                    {{ audio.created_at }}
                    <span class="divider">/</span>
                    {{ audio.author }}
                    <span class="divider">/</span>
                    {{ audio.category.name }}
                    <template v-if="audio.url">
                        <span class="divider">/</span>
                        {{ audio.times }}
                    </template>
                </template>
                <template #content>
                    <aplayer preload="none" v-if="haveMusic" :music="music" :list="lists" />
                    <p v-html="audio.content"></p>
                </template>
                <template #footer>
                    <van-row type="flex" justify="space-between">
                        <van-col span="12">阅读数 {{ audio.views }}</van-col>
                        <van-col span="12"><collect v-if="!loading" :medium="audio" /></van-col>
                    </van-row>
                </template>
            </t-content>
        </template>
    </div>
</template>

<script>
import { getMedia } from '@/api/media'
import Aplayer from 'vue-aplayer'
import Collect from '@/components/Collect'
import wechatMixin from '@/utils/mixin'
import TContent from '@/components/TContent'

export default {
    name: 'AudioShow',
    mixins: [wechatMixin],

    data() {
        return {
            loading: true,
            imgLoaded: false,
            audio: {
                title: '',
                author: '',
                times: 0,
                views: 0,
                url: null,
                accessories: []
            }
        }
    },
    metaInfo () {
        return {
            title: this.audio.title
        }
    },
    components: {
        Aplayer, Collect, TContent
    },
    computed : {
        lists () {
            const need = []
            this.audio.accessories.map(ace => {
                need.push({
                    src: ace.url,
                    title: ace.title,
                    author: ace.author ? ace.author : ' ',
                    pic: this.audio.thumb
                })
            })
            return need
        },
        haveMusic () {
            return this.audio.url !== null || this.audio.accessories.length > 0
        },
        music () {
            if (this.audio.url !== null) {
                return {
                    src: this.audio.url,
                    title: this.audio.title,
                    artist: this.audio.author ? this.audio.author : ' ',
                    pic: this.audio.thumb
                }
            }
            const first = this.audio.accessories[0]
            return {
                src: first.url,
                title: first.title,
                artist: first.author ? first.author : ' ',
                pic: this.audio.thumb
            }
        }
    },
    methods: {},
    created() {
        const mediaId = this.$route.params.id
        getMedia(mediaId).then(res => {
            this.audio = res.data
            this.loading = false
            this.registerWechatShare(this.audio)
        })
    }
};
</script>

<style lang="less">
    .aplayer {
        margin: 10px 0px !important;
        border-radius: 0px !important;
        .aplayer-list {
            height: inherit !important;
            ol {
                height: inherit !important;

                li {
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;

                    .aplayer-list-cur {
                        height: 40px;
                        top: 0px;
                    }
                }
            }
        }
        .aplayer-info {
            padding: 10px 7px !important;
            .aplayer-music {
                margin-left: 0px !important;
            }
        }
    }
    .aplayer-title {
        white-space: initial;
    }

    .divider {
        margin: 0px 2px;
    }
</style>
